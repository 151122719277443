import { useEffect } from "react";
import { toast } from "react-hot-toast";

const NetworkStatus = () => {
  let offlineToastId = null; 

  useEffect(() => {
    const handleOffline = () => {
    //  offlineToastId = toast.error("⚡ Connection lost! You're in offline mode.", { duration: Infinity,  });
      offlineToastId =  toast.error("⚡ Signal vanished!", { duration: Infinity });
    };

    const handleOnline = () => {
      if (offlineToastId) toast.dismiss(offlineToastId); 
     // toast.success("🚀 Back in the fast lane! You're online.", { duration: 2000 });
      toast.success("🚀 Connection reborn!", { duration: 3000 });
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  return null;
};

export default NetworkStatus;
