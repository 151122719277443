import axios from 'axios';
// import { logout } from '../V1/Modules/D5art/RMS/Slice/authSlice';
// import { store } from './store'; 
import { toast } from 'react-hot-toast';
import Cookies from 'js-cookie';
import { SERVER_URL } from '../Config/config';

const axiosInstance = axios.create({
  baseURL: SERVER_URL || 'https://d5art.com',
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('_authAccessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("Error:", error);

    const originalRequest = error.config;

    // if (error.response?.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;

    if (error.response?.status === 401) {
      delete originalRequest._retry;

      try {
        const refreshToken = Cookies.get('_authRefreshToken');

        if (!refreshToken) {
          console.error('No refresh token found. Redirecting to login.');
          localStorage.setItem("redirectAfterLogin", window.location.pathname + window.location.search);
          setTimeout(() => {
            window.location.href = '/seeker-login';
          }, 0);
          return Promise.reject(error);
        }

        const refreshResponse = await axios.post(
          `${SERVER_URL}/api/v1/auth/refresh-token`,
          { refreshToken },
          { headers: { Authorization: `Bearer ${refreshToken}` } }
        );

        const newAccessToken = refreshResponse.data.accessToken;
        Cookies.set('_authAccessToken', newAccessToken, { sameSite: 'Strict', secure: true });

        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error('Refresh token expired. Redirecting to login.');
        toast.error('Session expired. Please log in again.');
        localStorage.setItem("redirectAfterLogin", window.location.pathname + window.location.search);
        setTimeout(() => {
          window.location.href = '/seeker-login';
        }, 100);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
